<script>
import Vue                      from 'vue'
import { mapState, mapGetters } from 'vuex'

import { validationMixin }      from 'vuelidate'
import { EventBus }             from '../lib/event_bus.js'

import CountrySelect            from '../components/country_select.vue'
import RegionSelect             from '../components/region_select.vue'
import VatOfficeSelect          from '../components/vat_office_select.vue'
import VatNumberField           from '../components/vat_number_field.vue'

import { required, minLength, minValue, email, numeric } from 'vuelidate/lib/validators'

export default Vue.component('address-form', {
  components: {
    CountrySelect,
    RegionSelect,
    VatOfficeSelect,
    VatNumberField
  },
  mixins: [validationMixin],
  props: {
    streetNumberRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      address: {
        company: null,
        vat_number: null,
        profession: null,
        vat_office: null,
        first_name: null,
        last_name: null,
        email: null,
        telephone: null,
        mobile: null,
        street: null,
        street_number: null,
        zip: null,
        city: null,
        area: null
      },
      submitted: false,
      awaitingApi: false
    }
  },
  computed: {
    ...mapState([
      'order',
      'addressCreatedFromGiftList'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    isNewRecord() {
      let newAddress = true

      if (this.address.hasOwnProperty('id')) {
        if (this.address.id) {
          newAddress = false
        }
      }

      return newAddress
    },
    billingCountry() {
      return (this.$store.state.billing_address || {}).country
    },
    billingRegion() {
      return (this.$store.state.billing_address || {}).region
    },
    billingVatOffice() {
      return (this.$store.state.billing_address || {}).vat_office
    },
    formSubmitted() {
      return this.submitted
    }
  },
  watch: {
    billingCountry: function(newValue, oldValue) {
      if (this.address["country_id"] != newValue) {
        this.address["country_id"] = (newValue || {}).id
      }
    },
    billingRegion: function(newValue, oldValue) {
      if (this.address["region_id"] != newValue) {
        this.address["region_id"] = (newValue || {}).id
      }
    },
    billingVatOffice: function(newValue, oldValue) {
      if (this.address["vat_office_id"] != newValue) {
        this.address["vat_office_id"] = (newValue || {}).id
      }
    }
  },
  validations() {
    var validations = {}

    validations['address'] = {
      first_name: {
        required,
        minLength: minLength(2)
      },
      last_name: {
        required,
        minLength: minLength(2)
      },
      email: {
        required,
        minLength: minLength(5),
        email
      },
      telephone: {
        required,
        minLength: minLength(8),
        numeric
      },
      street: {
        required,
        minLength: minLength(2)
      },
      zip: {
        required,
        minLength: minLength(3)
      },
      city: {
        required,
        minLength: minLength(3)
      }
    }

    if (this.streetNumberRequired) {
      validations['address']['street_number'] = {
        required
      }
    }

    return validations
  },
  mounted() {
    EventBus.$on('populate-form', this.setAddress)
  },
  methods: {
    /*
     * Submits form. Creates or updates object
     */
    submit() {
      if (!this.awaitingApi) {
        this.submitted = true

        if (!this.submitDisabled()) {
          this.awaitingApi = true

          if (this.address.id) {
            this.$http.put(`${this.apiPath}/addresses/${this.address.id}`, { address: this.address }).then(response => {
              EventBus.$emit("reload-addresses")
              this.$store.dispatch('triggerEvent', { type: 'gy::address-updated', message: response.body.message })
              this.awaitingApi = false
            },
            (error) => {
              this.$store.dispatch('triggerEvent', {
                type: 'gy::address-updated',
                message: error.body.message
              })

              this.awaitingApi = false
            })
              .catch((error) => {
                this.awaitingApi = false
              })
          } else {
            this.$http.post(`${this.apiPath}/addresses`, { address: this.address }).then(response => {
              EventBus.$emit("reload-addresses")
              this.$store.dispatch('triggerEvent', { type: 'gy::address-created', message: response.body.message })

              if (this.addressCreatedFromGiftList) {
                EventBus.$emit("set-address-to-giftlist", response.body.address_id)
              }

              this.awaitingApi = false
            },
            (error) => {
              this.$store.dispatch('triggerEvent', { type: 'gy::address-created', message: error.body.message })
              this.awaitingApi = false
            })
              .catch((error) => {
                this.awaitingApi = false
              })
          }
        }
      }
    },
    /*
     * It initializes vue object address on component.
     * @param {Object} address - the address to set
     */
    setAddress(address) {
      this.submitted = false
      this.address = address
      this.$v.address.$reset()
    },
    /*
     * Adds error classes on element
     * @param {Object} element - the element
     */
    addValidateClass(element) {
      return { error: element.$error, valid: element.$dirty && !element.$invalid }
    },
    /*
     * Disables sumit if errors exist
     */
    submitDisabled() {
      return this.awaitingApi || this.$v.$invalid || this.isCountryInvalid() || this.isRegionInvalid() || this.isVatOfficeInvalid() || this.isVatNumberInvalid()
    },
    /*
     * Validates region
     */
    isRegionInvalid() {
      return this.$refs.selectRegion.$v.$invalid
    },
    /*
     * Validates country
     */
    isCountryInvalid() {
      return this.$refs.selectCountry.$v.$invalid
    },
    /*
     * Validates vat office
     */
    isVatOfficeInvalid() {
      if (this.$refs.selectVatOffice) {
        return this.$refs.selectVatOffice.$v.$invalid
      }
      else {
        return false
      }
    },
    isVatNumberInvalid() {
      if (this.$refs.insertVatNumber) {
        return !this.$refs.insertVatNumber.validNumber
      }
      else {
        return false
      }
    },
    displayErrors() {
      return this.submitted && this.submitDisabled()
    },
    displayErrorByField(field) {
      return this.submitted && this.submitDisabled() && this.$v.address[field].$invalid
    }
  }
})
</script>
