import Vue from 'vue';

Vue.filter('uppercase', value => {
  if (!value) return ''

  value = value.toString().toUpperCase()

  let search  = Array('Ά', 'Έ', 'Ί', 'Ϊ́', 'Ή', 'Ύ', 'Ϋ́', 'Ό', 'Ώ')
  let replace = Array('Α', 'Ε', 'Ι', 'Ϊ', 'Η', 'Υ', 'Ϋ', 'Ο', 'Ω')

  for (var i = 0, len = search.length; i < len; i++) {
    value = value.replace(search[i], replace[i])
  }

  return value
})
