<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import NiceI18n     from '../../lib/nice_i18n'
import niceModal    from '../../mixins/nice_modal'
import Product      from '../product.vue'
import { EventBus } from '../../lib/event_bus.js'

export default Vue.component('gift-list-item', {
  extends: Product,
  mixins: [niceModal],
  props: {
    item: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      giftList: {}
    }
  },
  computed: {
    ...mapGetters([
      'apiPath'
    ]),
    computedProductId() {
      if (this.item != undefined) {
        return this.item.product_id
      }

      return undefined
    }
  },
  methods: {
    /*
     * Removes a product from Gift List
     */
    removeProduct() {
      let message      = NiceI18n.t('gift_lists.items.prompt_to_delete')
      let submitButton = { title: NiceI18n.t("gift_lists.delete"), handler: () => { this._removeProduct() } }
      let cancelButton = { title: NiceI18n.t("gift_lists.cancel_destroy"), default: true }

      this.showModal(message, [submitButton, cancelButton])
    },

    /*
     * The actual API call to remove a product from Gift List
     */
    _removeProduct() {
      this.$http.delete(`${this.apiPath}/gift_lists_items/${this.item.id}`).then(response => {
        EventBus.$emit("reload-gift-lists")
        this.$modal.hide('dialog')
        this.$store.dispatch('triggerEvent', { type: 'gy::giftlist-item-removed', message: response.body.message })
      },
      (error) => {
        this.$store.dispatch('triggerEvent', { type: 'gy::giftlist-item-removed', message: response.body.message })
      })
        .catch((error) => {})
    }
  }
})
</script>
