import * as Cookies from "js-cookie"
import queryString from "query-string"

export default class GYCookie {
  constructor(args = {}) {
    this.preconsentedPolicy = false

    this.consent = {
      persisted: false,
      necessary: false,
      analytics: false,
      marketing: false,
      other: false
    }

    this.reinitialized = args["reinitialized"] || false

    this.getConsent()
    this.appendTrackingParamsToAllUrls()
    this.initializeScripts()
  }

  getConsent() {
    let consent = Cookies.getJSON("GDPRCookieConsent")

    if (consent != undefined && consent.constructor === Object && Object.keys(consent).length > 0) {
      this.consent = consent
      this.consent["persisted"] = true
    }
  }

  consentedFor(type) {
    return (this.consent[type] || false)
  }

  initializeScripts() {
    let elements = document.getElementsByTagName("script")

    for (let el of elements) {
      if (el.hasAttribute("data-cookieconsent") && el.hasAttribute("type") && el.getAttribute("type").toLowerCase() == "text/plain") {
        // Break execution and continue to next element if script has document.write() to avoid white screen of death
        if (this.reinitialized && this.scriptContainsDocumentWrite(el)) {
          continue
        }

        if (this.consentedFor(el.getAttribute("data-cookieconsent")) || (this.preconsented_policy && !this.consent["persisted"])) {
          this.appendToDOM(el)
        }

        this.enabled = true
      }
    }
  }

  scriptContainsDocumentWrite(el) {
    let excludedScripts = ["FVPMFrezydermshop"]

    return excludedScripts.some((v) => {
      return el.text.indexOf(v) != -1
    })
  }

  /*
   * Appends marketing params to all URLs in order for when the visitor clicks on an URL to be captured
   * correctly from Google Analytics. Without this all users are captured as direct users, since these
   * params are lost on the next click.
   */
  appendTrackingParamsToAllUrls() {
    if (this.consentedFor("marketing") && this.consentedFor("analytics")) {
      return
    }

    let excludedHrefs   = ["#", "tel", "mailto", "skype", "ftp", "file"]
    let marketingParams = ["gclid", "utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "network"]
    let startWithParams = ["lkws_"]
    let params          = queryString.parse(queryString.extract(window.location.href))

    // Create intersection of params & marketingParams
    let activeParams = Object.keys(params).filter(value => -1 !== marketingParams.indexOf(value))

    var dynamicParams = Object.keys(params).map(value => {
      return Object.values(startWithParams).map(subvalue => value.startsWith(subvalue) ? value : undefined).filter(Boolean)
    }).filter(value => value.length > 0).reduce((acc, val) => acc.concat(val), [])

    activeParams = activeParams.concat(dynamicParams)

    if (activeParams.length > 0) {
      for (let anchor of document.links) {
        let href = anchor.getAttribute("href")

        // Check if url is in excludedHrefs. If it is skip it and continue to next.
        let shouldContinue = excludedHrefs.some(function(arrVal) {
          return href.startsWith(arrVal)
        })

        if (shouldContinue)
          continue

        let parsed = queryString.parseUrl(href)
        let query  = parsed["query"]

        for (let param of activeParams) {
          query[param] = params[param]
        }

        if (parsed["url"] && parsed["url"].length > 0) {
          anchor.setAttribute("href", `${parsed["url"]}?${queryString.stringify(query)}`)
        }
      }
    }
  }

  appendToDOM(element) {
    var l = element.parentNode,
      h = element.nextElementSibling,
      d = document.createElement("script")

    for (var i = 0; i < element.attributes.length; i++) {
      var attribute = element.attributes[i]
      d.setAttribute(attribute.name, attribute.value)
    }

    d.text = element.text
    d.setAttribute("type", "text/javascript")
    l.removeChild(element)
    l.insertBefore(d, h || null)
  }
}