<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import AddressForm from './address_form.vue'
import AddressItem from './address_item.vue'

import { EventBus } from '../lib/event_bus.js'

export default Vue.component('addresses', {
  components: {
    AddressForm,
    AddressItem
  },
  props: {
    giftlist: {
      default: () => {
        return {}
      },
      type: Object
    },
    val: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      addresses: []
    }
  },
  computed: {
    ...mapState([
      'order',
      'addressCreatedFromGiftList'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    addressesEmpty() {
      return (this.addresses || []).length == 0
    }
  },
  mounted() {
    this.loadAddresses()
    EventBus.$on("reload-addresses", this.loadAddresses)
  },
  methods: {
    /*
     * Calls this.add() if given value is "newAddress"
     */
    selectAddress(value) {
      if (value == "newAddress") {
        this.$store.state.addressCreatedFromGiftList = true
        this.add()
      }
    },

    /*
     * Fetches all user addresses from api.
     * @event - gy:addresses-loaded is triggered
     */
    loadAddresses() {
      this.$http.get(`${this.apiPath}/addresses`).then(response => {
        this.addresses = response.body.addresses
        this.$store.dispatch('triggerEvent', 'gy:addresses-loaded')
      })
    },

    /*
    * Initializes a new address instance from api and populates form with address attributes.
    * @event - gy::address-form-populated is triggered (should be used to call the modal or page that contains the address form)
    */
    add() {
      EventBus.$emit("populate-form", {})
      this.$store.dispatch('triggerEvent', 'gy::address-form-populated')
    }
  }
})
</script>
