import Vue from 'vue'
import VueResource from 'vue-resource'

Vue.use(VueResource)

export default class ConsentChecker {
  constructor() {
    this.setDomainAndToken()
  }

  setDomainAndToken() {
    if (window.gy_moad != undefined) {
      this.domain = window.gy_moad.domain
      this.token  = window.gy_moad.token

      if (window.gy_moad.locale != undefined) {
        this.locale = window.gy_moad.locale
      } else {
        this.locale = "el"
      }
    } else {
      this.domain = window.location.hostname
      this.token  = ""
      this.locale = "el"
    }
  }

  checkForGivenConsent(email) {
    let resource = new Vue
    return resource.$http.post("consents/get_consent",
      {
        domain: this.domain,
        locale: window.locale || this.locale,
        email: email
      },
      { headers: { "X-Authorization-Token": this.token } }
    )
  }
}