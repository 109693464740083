export default {
  computed: {
    versions() {
      return (this.$el.attributes.versions || {}).value
    },
    stringifiedVersions() {
      if (this.versions) {
        return JSON.stringify(JSON.parse(this.versions))
      }

      return undefined
    }
  }
}