<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { EventBus } from '../../lib/event_bus.js'

export default Vue.component('store-list', {
  props: {
    stores: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    count() {
      return this.stores.length
    },
    totalCount() {
      return this.$parent.stores.length
    },
    selectedStore() {
      return this.$parent.selectedStore
    }
  },
  methods: {
    selectStore(store) {
      EventBus.$emit('store-selected', store)
    }
  }
})
</script>