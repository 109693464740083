import Vue from 'vue'
import VueResource from 'vue-resource'
import { mapState, mapGetters } from 'vuex'
import { store } from '../lib/store'
import $ from 'jquery'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request, next) {
  // modify headers
  if (document.querySelector('meta[name="csrf-token"]'))
    request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)

  request.headers.set('Authorization', window.api_token)
  // continue to next interceptor
  next()
})

$('#gy-login-form, .gy-login-form').each(function() {
  new Vue({
    el: this,
    store,
    data() {
      return {
        user: {},
        notAuthorized: false
      }
    },
    computed: {
      ...mapState([
        'order',
        'history'
      ]),
      ...mapGetters([
        'siteLocalePath'
      ])
    },
    mounted() {
      if (this.$el.attributes.returnTo) {
        this.returnTo = this.$el.attributes.returnTo.value
      }
    },
    methods: {
      /*
       * Submits login form for sign in.
       * @param {Object} event - the event of button
       * @event - gy::user-logged-in is triggered for successful login
       * @event - gy::user-not-authorized is triggered for unsucessful login
       */
      submit(event) {
        this.$http.post(`${this.siteLocalePath}/users/sign_in`, { user: this.user } ).then(response => {
          this.$store.dispatch('triggerEvent', 'gy::user-logged-in')

          if (this.history && this.history.length > 0) {
            let element = this.history[this.history.length - 1]
            element.component[element.method]()
            this.$store.state.history = []
          }

          if (this.returnTo) {
            window.location.href = this.returnTo
          }
        }, response => {
          if (response.status == 401) {
            this.notAuthorized = true
            this.$store.dispatch('triggerEvent', { type: 'gy::user-not-authorized', message: response.body.error })
          }
        })
      }
    }
  })
})
