import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { store } from '../../lib/store'
import Utils from '../../lib/utils'
import VueResource from 'vue-resource'
import $ from 'jquery'

Vue.use(VueResource)

// Components
import ConsentsContainer from '../../components/moad/consents_container.vue'

export default Vue.extend({
  store,
  data() {
    return {
    }
  },
  computed: {
    ...mapState([
    ])
  },
  mounted() {
  },
  methods: {
  }
})
