<template>
  <select
    v-model="giftlist"
    @change="setActiveGiftList()"
  >
    <option
      v-for="list in giftLists"
      :key="list.id"
      :value="list"
    >
      {{ list.title }}
    </option>
  </select>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

// Lib
import Utils        from '../../lib/utils'
import { EventBus } from '../../lib/event_bus.js'

export default Vue.component('gift-list-select-active', {
  data() {
    return {
      giftlist: {}
    }
  },
  computed: {
    ...mapState([
      'order',
      'giftLists',
      'activeGiftListId'
    ]),
    ...mapGetters([
      'apiPath',
      'activeGiftList'
    ])
  },
  mounted() {
    EventBus.$on("select-option", this.setActiveGiftList)
  },
  methods: {
    /*
     * Sets active gift list
     */
    setActiveGiftList(gift_list = null) {
      // triggered when a product is added to a gift list
      if (gift_list != null) {
        this.$store.commit('setActiveGiftListId', gift_list.id)
        this.giftlist =  this.activeGiftList
        EventBus.$emit("reload-gift-lists")
      }
      // triggered on page load
      else if (this.$store.state.activeGiftListId === null) {
        this.$store.state.activeGiftListId = (this.giftLists[0] || {}).id

        if (Object.keys(this.giftlist).length === 0) {
          this.giftlist = this.giftLists[0]
        }

        this.$store.commit('setActiveGiftListId', (this.giftlist || {}).id)
      }
      else {
        if (this.giftlist.id != undefined) {
          this.$store.commit('setActiveGiftListId', this.giftlist.id)
        }

        this.giftlist = this.activeGiftList
      }
    }
  }
})
</script>
