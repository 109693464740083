import Vue from 'vue'
import VueResource from 'vue-resource'
import { mapGetters } from 'vuex'
import { store } from '../lib/store'
import $ from 'jquery'
import Addresses from '../components/addresses.vue'
import AddressForm from '../components/address_form.vue'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request, next) {
  // modify headers
  if (document.querySelector('meta[name="csrf-token"]')) {
    request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)
    request.headers.set('Authorization', window.api_token)
    // continue to next interceptor
    next()
  }
})

$('#gy-address').each(function() {
  new Vue({
    el: this,
    store,
    components: {
      Addresses,
      AddressForm
    },
    data: {},
    computed: {
      ...mapGetters([
        'apiPath'
      ])
    },
    mounted() {}
  })
})
