<template>
  <a
    href="#"
    :class="{ active: isActive }"
    class="gdpr-cm-category-anchor"
    @click.prevent="select()"
  >
    <i
      v-if="!isActive"
      class="mdi mdi-circle-outline"
    />
    <i
      v-if="isActive"
      class="mdi mdi-circle"
    />
    {{ category.title }}
  </a>
</template>
<script>
import Vue from 'vue'
import { EventBus } from '../../lib/event_bus.js'

export default Vue.component('cookie-category', {
  props: {
    category: {
      default: () => {
        return {}
      },
      type: Object
    }, 
    selectedCategory: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      active: null
    }
  },
  computed: {
    isActive() {
      if (this.selectedCategory == this.category)
        return true

      return this.active
    }
  },
  mounted() {
    EventBus.$on("cookieCategorySelected", this.deselect)
  },
  methods: {
    select() {
      EventBus.$emit("cookieCategorySelected", this.category)
      this.active = true
    },
    deselect(category) {
      this.active = (this.category == category)
    }
  }
})
</script>

<style lang="scss">
  .gdpr-cm-category-anchor {
    i {
      color: #09a501;
      padding-right: 4px;
    }
  }
</style>