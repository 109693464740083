<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import NiceI18n from '../../lib/nice_i18n'
import SocialSharing from 'vue-social-sharing'

Vue.use(SocialSharing)

export default Vue.component('gift-list-share', {
  computed: {
    ...mapGetters([
      'activeGiftList'
    ]),
    shareLink() {
      return location.protocol + '//' + location.host + "/gift_lists/" + this.activeGiftList.id
    }
  },
  methods: {
    /*
     * Adds gift list url to clipboard
     */
    addToClipboard() {
      this.$refs.share_text.select()
      document.execCommand('copy')
    }
  }
})
</script>
