<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import NiceI18n     from '../../lib/nice_i18n'
import niceModal    from '../../mixins/nice_modal'
import { EventBus } from '../../lib/event_bus.js'

export default Vue.component('gift-list', {
  mixins: [niceModal],
  props: {
    giftlist: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  computed: {
    ...mapGetters([
      'apiPath'
    ])
  },
  methods: {
    /*
     * Redirects to gift list page
     */
    show() {
      window.location.href = '/gift_lists/' + this.giftlist.id + "/edit"
    },

    /*
     * Triggers edit gift list popup
     */
    edit() {
      EventBus.$emit("populate-giftlist-form", this.giftlist)
      this.$store.dispatch('triggerEvent', 'gy::giftlist-form-populated')
    },

    /*
     * Deletes Gift List
     */
    destroy() {
      let message      = NiceI18n.t('gift_lists.prompt_to_delete')
      let submitButton = { title: NiceI18n.t("gift_lists.delete"), handler: () => { this._delete() } }
      let cancelButton = { title: NiceI18n.t("gift_lists.cancel_destroy"), default: true }

      this.showModal(message, [submitButton, cancelButton])
    },

    /*
     * The actual API call to delete a Gift List
     */
    _delete() {
      this.$http.delete(`${this.apiPath}/gift_lists/${this.giftlist.id}`).then(response => {
        EventBus.$emit("reload-gift-lists")
        this.$modal.hide("dialog")
        this.$store.dispatch('triggerEvent', { type: 'gy::giftlist-deleted', message: response.body.message })
      },
      (error) => {
        this.$store.dispatch('triggerEvent', { type: 'gy::giftlist-deleted', message: error.body.message })
      })
        .catch((error) => {})
    }
  }
})
</script>
